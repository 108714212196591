import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const LimbusDatabaseACM: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Advanced Combat Mechanics</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_advanced.webp"
            alt="Combat mechanics"
          />
        </div>
        <div className="page-details">
          <h1>Advanced Combat Mechanics</h1>
          <h2>
            A guide that explains unique mechanics and buffs that applies to
            certain Identities and/or Teams.
          </h2>
          <p>
            Last updated: <strong>13/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide goes over mechanics that are mentioned in our Reviews, but
          not elaborated on in detail to fully iterate what an ID or team of IDs
          can do with their utility and how they fit into teams and/or adopt a
          certain play style. It’ll also mention all IDs and teams tied to that
          specific mechanic. This guide also aims to explain certain gameplay
          mechanics that aren’t explained enough in-game.
        </p>
        <SectionHeader title="Discarding and Insight Locking" />
        <p>
          <strong>
            Related IDs: Dieci Rodion, Dieci Hong Lu, Dieci Yi Sang, and Dieci
            Meursault
          </strong>
        </p>
        <p>
          Discarding is relatively straightforward in what it does. The effect
          randomly deletes skills that are selectable during the Chaining phase
          from the Dashboard, allowing you to cycle through skills faster.
          However, for the Dieci Association of IDs, they gain or lose Insight
          depending on the rank of the last skill you’ve discarded, for example,
          Discarding an S3 sets your Insight to 3, in which other skills that
          are dependent on your value of Insight will be activated.
        </p>
        <p>
          Insight Locking is a technique that can be used by discarding a
          certain skill, and then using a defense skill so that you won’t
          discard anything else, effectively locking your Insight to a certain
          value. Dieci Hong Lu is the most well known case of Insight Locking as
          both his damage and Clashes skyrocket if his Insight is at a value of
          3. Other Dieci IDs can utilize Insight Locking as well, Dieci Yi Sang
          for example being able to inflict more sinking on his S2 if he is kept
          at a higher Insight value. However, more often than not it’s not
          really worth using this technique with IDs such as Dieci Yi Sang,
          Rodion, and Meursault as they don’t really benefit from Insight
          Locking as much as Hong Lu.
        </p>
        <SectionHeader title="Swordplay of the Homeland" />
        <p>
          <strong>
            Related IDs: Blade Lineage Meursault, Blade Lineage Yi Sang, Blade
            Lineage Faust, Blade Lineage Sinclair, Blade Lineage Outis, Blade
            Lineage Don
          </strong>
        </p>
        <p>
          Swordplay of the Homeland is relatively straightforward and is
          exclusive to the Blade Lineage faction of IDs. Blade Lineage Meursault
          provides this status with his Passive of the same name which makes him
          pretty much mandatory for a Blade Lineage team to actually be usable,
          because without this status, they all severely lack in multiple
          different areas, whether it be Poise generation or just damage.
        </p>
        <p>
          Starting out with the Passive, whenever he gains Poise, whether it be
          Potency or Count, he will give +1 Poise Potency and Count to 2 other
          Blade Lineage allies with no or the least Poise, and if you have the
          full team, he’ll give 2 Poise Potency and Count instead. He will
          always also apply the Swordplay of the Homeland buff to all other
          Blade Lineage allies.
        </p>
        <p>
          The buff, Swordplay of the Homeland, buffs the unit’s S1 and S2 if
          they have 5+ Poise by giving them Coin Power, the amount of which is 3
          divided by the number of Coins, which can always be a minimum of 1,
          and deals +(30/# of Coins) damage on a Critical Hit. At 7+ Poise it
          also gives the unit’s S3 +(30/# of Coins)% more damage and +(50/# of
          Coins)% on Critical Hit .
        </p>
        <p>
          This makes your game plan with the team simple: Activate Blade Lineage
          Meursault’s Passive as often as possible for the other Blade Lineage
          allies. This is easily done by getting 3 Pride Resonance for his
          Passive to activate.
        </p>
        <p>
          Swordplay of the Homeland also has 2 more variations as a buff, Sword
          of the Homeland - Rending and Sword of the Homeland - Penetrating,
          both of which also provided by Blade Lineage Meursault.
        </p>
        <p>
          Sword of the Homeland - Rending would give its user Final Power by the
          effect’s count for their Skill 1 and Sword of the Homeland Mending
          would do the same but for the user’s Skill 2.
        </p>
        <SectionHeader title="Charge Potency" />
        <p>
          <strong>
            Related IDs: Multicrack Faust, Multicrack Heathcliff, W Corp Outis
          </strong>
        </p>
        <p>
          Charge Potency is an attribute that only certain IDs can obtain if
          they have a Passive for it. Charge Potency does something different
          depending on which ID has it, and is mainly used as a certain type of
          permanent Counter which allows IDs who can obtain it to scale further
          in longer fights, and isn’t 100% tied down to the burst nature of
          Charge Count IDs like W Ryoshu and W Don.
        </p>
        <SectionHeader title="Butterfly (“The Living” and “The Departed”)" />
        <p>
          <strong>
            Related IDs and EGO: Solemn Lament Gregor (EGO), Lobotomy EGO:
            Solemn Lament Yi Sang
          </strong>
        </p>
        <p>
          Butterfly is split into two different keywords, “The Living” and “The
          Departed.” Its Potency is called The Living, and its Count is called
          The Departed.
        </p>
        <p>
          Whenever you attack an enemy with Butterfly, you heal “The Living”
          divided by 4 SP, so if the enemy would have 8 of “The Living”, you
          would heal 2 SP on hit. The enemy will also take Gloom damage if their
          SP is less than 0, the number of which is determined by the amount of
          Sinking Potency the enemy has, divided by 5, and then multiplied by
          the number of The Departed you have. For example, if you had 10
          Sinking Potency on the enemy, and 10 “The Departed” on the enemy,
          you’ll be doing 20 Gloom Damage to the enemy. The damage dealt from
          this status is capped at 30 per hit, and would always deal half damage
          to targets without SP.
        </p>
        <p>
          Finally, at the end of the turn, "The Departed" is set to 0, and "The
          Living" is converted to Sinking Potency, which is then converted to
          The Departed.
        </p>
        <p>
          Butterfly is a great source of extra Gloom Damage as well as Sinking
          Potency, thanks to it’s conversion effect from "The Living". Combined
          with fact that it can also provide SP healing, it makes Butterfly a
          great alternative for damaging enemies with Sinking.
        </p>
        <SectionHeader title="Strategic R&R Mode" />
        <p>
          <strong>Related IDs: Devyat Association Rodion</strong>
        </p>
        <p>
          Strategic R&R Mode is a special condition that’s activated upon
          certain circumstances. The unit who activates it recovers from Stagger
          and Retreats from the Encounter at the end of the turn, which does not
          count as a death.
        </p>
        <p>
          This mode has a bunch of utility behind it, in Chain Battles, the unit
          will give their skill slots to a substituted unit and move to the very
          last place in the Backup units’ Deployment order, although if there’s
          no other Backup Units remaining to swap places with, they will escape
          from the Encounter entirely. In normal battles, they will just escape
          from the encounter entirely. Although, if the unit who activates
          Strategic R&R Mode escapes from the encounter entirely, they will give
          their skill slot to the #1 Selected Identity, resulting in them having
          2 skill slots which enables that unit to fight with double the
          efficiency.
        </p>
        <SectionHeader title="Clashable Defenses" />
        <p>
          <strong>
            Related IDs: Zwei Ishmael, Zwei Sinclair, Wild Hunt Heathcliff
          </strong>
        </p>
        <p>
          Clashable Defense Skills are Defense Skills that can be clashed with
          attacks. As of writing there are only two types of Clashable Defenses,
          those being Counter and Guard.
        </p>
        <p>
          Clashable Guard Skills (also called Power Guard){' '}
          <strong>
            raise a target’s Stagger Threshold by the user’s final Clash Power
            when it wins a clash. When it loses a clash, the attacker’s final
            skill power will be reduced by the user’s Final Power.
          </strong>{' '}
          It also would not gain Final Power from Resonance and the Level
          difference between the Attack Skill. To explain this further, because
          this Defense Skill is a guard, it’ll
          <strong>gain 1 Clash Power and 1 Final Power</strong> for every 3
          Defense Level above the opponent’s and it scales the more Defense Up
          Level you have on the unit.
        </p>
        <p>
          Clashable Counters does not have any unique ability similar to
          Clashable Guards. They only act as an additional skill to the kit
          they’re on, and in some cases, a really good alternative to using an
          ID’s S1. Clashable Counters also scale only with Offense Level.
        </p>
        <SectionHeader title="Unbreakable Coins" />
        <p>
          <strong>Related IDs: Zwei Ishmael, Zwei Sinclair</strong>
        </p>
        <p>
          Unbreakable Coins do not break upon losing a clash. After you’ve lost
          a clash and the enemy attacks you, the coin will still activate with
          it’s Coin Power fixed to 1 and can be really useful in activating that
          coin’s effects even after you lose a clash.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusDatabaseACM;

export const Head: React.FC = () => (
  <Seo
    title="Advanced Combat Mechanics | Limbus Company | Prydwen Institute"
    description="A guide that explains unique mechanics and buffs that applies to certain Identities and/or Teams."
    game="limbus"
  />
);
